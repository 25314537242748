// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-adipec-2024-js": () => import("./../../../src/pages/adipec2024.js" /* webpackChunkName: "component---src-pages-adipec-2024-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-boucheron-js": () => import("./../../../src/pages/boucheron.js" /* webpackChunkName: "component---src-pages-boucheron-js" */),
  "component---src-pages-brief-history-js": () => import("./../../../src/pages/brief-history.js" /* webpackChunkName: "component---src-pages-brief-history-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-data-storage-innovation-js": () => import("./../../../src/pages/data-storage-innovation.js" /* webpackChunkName: "component---src-pages-data-storage-innovation-js" */),
  "component---src-pages-datacenters-3-pct-js": () => import("./../../../src/pages/datacenters3pct.js" /* webpackChunkName: "component---src-pages-datacenters-3-pct-js" */),
  "component---src-pages-facilities-js": () => import("./../../../src/pages/facilities.js" /* webpackChunkName: "component---src-pages-facilities-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-memory-crystal-js": () => import("./../../../src/pages/memory-crystal.js" /* webpackChunkName: "component---src-pages-memory-crystal-js" */),
  "component---src-pages-newspage-js": () => import("./../../../src/pages/newspage.js" /* webpackChunkName: "component---src-pages-newspage-js" */),
  "component---src-pages-optical-device-fabrication-js": () => import("./../../../src/pages/optical-device-fabrication.js" /* webpackChunkName: "component---src-pages-optical-device-fabrication-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-recognition-js": () => import("./../../../src/pages/recognition.js" /* webpackChunkName: "component---src-pages-recognition-js" */),
  "component---src-pages-spie-2025-js": () => import("./../../../src/pages/spie2025.js" /* webpackChunkName: "component---src-pages-spie-2025-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

